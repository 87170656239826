<template>
  <v-container class="mt-4">
    <page-main
      title="Summary"
      nextText="Let’s get started!"
      buttonTabIndex="6"
      eventName="nav"
      @nav="nav('/ReviewGeneralInfo')"
    >
      <ul class="text-body-big">
        <li class="mb-4">
          <span class="font-weight-bold">Congratulations on taking the first steps toward building a standout resume with Resumation! You've learned:</span>
          <ul>
            <li>
              What Resumation is  and how it serves as your virtual guide for crafting a great resume.
            </li>
            <li>
              Key components of an effective resume, focusing on showcasing your accomplishments and tailoring content to the job.
            </li>
            <li>
              The preparation materials you should have handy – you are now ready to begin!
            </li>
          </ul>
        </li>
        <li>
          <span class="font-weight-bold">We will guide you through crafting your resume with prompts and questions in the following areas:</span>
          <ul>
            <li>
              <span class="font-weight-bold">General Information</span>
              <ul>
                <li>
                  The basics and beyond, including all necessary details typically found on a resume.
                </li>
              </ul>
            </li>
            <li>
              <span class="font-weight-bold">Education</span>
              <ul>
                <li>
                  Dive into your educational background, highlighting schools, achievements, and extracurriculars.
                </li>
              </ul>
            </li>
            <li>
              <span class="font-weight-bold">Experience</span>
              <ul>
                <li>
                  Detail your professional journey, including work, roles, and standout accomplishments.
                </li>
              </ul>
            </li>
            <li>
              <span class="font-weight-bold">Additional Information</span>
              <ul>
                <li>
                  Here's where you can add anything else relevant that hasn't been covered, like unique skills or experiences.
                </li>
              </ul>
            </li>
            <li>
              <span class="font-weight-bold">Resume Review</span>
              <ul>
                <li>
                  Finally, you'll have the chance to review, download, and, for Resumation Plus users, customize and save multiple versions of your resume tailored to different opportunities.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <span class="font-weight-bold">With these steps, you are well on your way! Click next to begin your journey with Resumation.</span>
        </li>
      </ul>
    </page-main>
  </v-container>
</template>

<script>
export default {
  name: "Summary",
  methods: {
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    }
  }
};
</script>

<style scoped>
.container ul ul {
  list-style-type: disc;
}
</style>